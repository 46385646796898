.googleReviewStar {
  fill: #f5c113;
  height: 12px;
  width: 12px;
  margin-right: 2px;
  margin-bottom: 4px;

  .totalReviewCount {
    margin-left: 4px;
  }
}
